export default {
    namespaced: true,
    state: {
        filters: {
            is_active: null,
            user_id: null,
            placements: null,
            nameScope: null
        }
    },
    getters: {
        GET_FILTERS: state => {
            return state.filters
        },
        GET_SEARCH: state => {
            return state.filters.nameScope
        }
    },
    mutations: {
        SET_FILTER: (state, payload) => {
          state.filters[payload.name] = payload.value
        }
    },
    actions: {
        setFilter: (context, payload) => {
          context.commit('SET_FILTER', payload)
        },
        setIsActive: (context, payload) => {
            context.commit('SET_FILTER', { name: 'is_active', value: payload })
        },
        setUserId: (context, payload) => {
            context.commit('SET_FILTER', { name: 'user_id', value: payload })
        },
        setPlacements: (context, payload) => {
            context.commit('SET_FILTER', { name: 'placements', value: payload })
        },
        setSearch: (context, payload) => {
            context.commit('SET_FILTER', { name: 'nameScope', value: payload })
        }
    }
}
