export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../../views/Dashboard'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/users-list',
    name: 'users-table',
    component: () => import('../../views/Users'),
    meta: {
      resource: 'users-table',
      action: 'read'
    }
  },
  {
    path: '/advertisers/',
    name: 'advertisers',
    component: () => import('../../views/Advertisers'),
    meta: {
      resource: 'advertisers',
      action: 'read'
    }
  },
  {
    path: '/advertisers/:user_id',
    name: 'advertisersUser',
    component: () => import('../../views/Advertisers'),
    meta: {
      resource: 'advertisers',
      action: 'read'
    }
  },
  {
    path: '/publishers',
    name: 'publishers',
    component: () => import('../../views/Publishers'),
    meta: {
      resource: 'publishers',
      action: 'read'
    }
  },
  {
    path: '/advertising-campaigns',
    name: 'advertising-campaigns',
    component: () => import('../../views/AdvertisingCampaigns'),
    meta: {
      resource: 'advertising-campaigns',
      action: 'read'
    }
  },
  {
    path: '/advertising-campaigns/:user_id',
    name: 'advertising-campaignsAdvertiser',
    component: () => import('../../views/AdvertisingCampaigns'),
    meta: {
      resource: 'advertising-campaigns',
      action: 'read'
    }
  },
  {
    path: '/publisher-statistics',
    name: 'publisher-statistic',
    component: () => import('../../views/PublisherStatistic'),
    meta: {
      resource: 'publisher-statistic',
      action: 'read'
    }
  },
  {
    path: '/dsp-statistics',
    name: 'dsp-statistic',
    component: () => import('../../views/ManagerDSPStatistics'),
    meta: {
      resource: 'dsp-statistic',
      action: 'read'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../../views/AdminStatistic'),
    meta: {
      resource: 'admin-statistic',
      action: 'read'
    }
  },
  {
    path: '/manager-statistic',
    name: 'manager-statistic',
    component: () => import('../../views/ManagerStatistic'),
    meta: {
      resource: 'manager-statistic',
      action: 'read'
    }
  },
  {
    path: '/advertiser-statistic',
    name: 'advertiser-statistic',
    component: () => import('../../views/AdvertiserStatistics'),
    meta: {
      resource: 'advertiser-statistic',
      action: 'read'
    }
  },
  {
    path: '/campaign-statistic',
    name: 'campaign-statistic',
    component: () => import('../../views/CampaignStatistic'),
    meta: {
      resource: 'campaign-statistic',
      action: 'read'
    }
  },
  {
    path: '/platforms',
    name: 'platforms',
    component: () => import('../../views/Platforms'),
    meta: {
      resource: 'platforms',
      action: 'read'
    }
  },
  {
    path: '/player',
    name: 'player',
    component: () => import('../../views/Player'),
    meta: {
      resource: 'player',
      action: 'read'
    }
  },
  {
    path: '/platforms/:user_id',
    name: 'platformsUser',
    component: () => import('../../views/Platforms'),
    meta: {
      resource: 'platforms',
      action: 'read'
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('../../views/Finance'),
    meta: {
      resource: 'finance',
      action: 'read'
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('../../views/Logs'),
    meta: {
      resource: 'logs',
      action: 'read'
    }
  },
  {
    path: '/placements',
    name: 'placements',
    component: () => import('../../views/Placements'),
    meta: {
      resource: 'placements',
      action: 'read'
    }
  },
  {
    path: '/placements/:user_id',
    name: 'placementsUser',
    component: () => import('../../views/Placements'),
    meta: {
      resource: 'placements',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../../views/Login'),
    meta: {
      layout: 'full',
      resource: 'auth',
      action: 'read'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../../views/404')
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('../../views/Login'),
    meta: {
      layout: 'full',
      resource: 'auth',
      action: 'read'
    }
  }
]
