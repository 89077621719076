import Vue from 'vue'
import JsonExcel from "vue-json-excel"
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {BootstrapVue, IconsPlugin} from "bootstrap-vue"
import * as Sentry from "@sentry/vue"
import VueContentPlaceholders from 'vue-content-placeholders'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
//
// // Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueContentPlaceholders)

// Composition API
Vue.use(VueCompositionAPI)

Vue.component("downloadExcel", JsonExcel)

Vue.use(BootstrapVue, {
  formTextControls: {
    autocomplete: 'off'
  }
})

Vue.filter('formatNumber', function (value) {
  if (typeof value !== 'number') return value
  return new Intl.NumberFormat().format(value)
  // value = value.toString()
  // return value.charAt(0).toUpperCase() + value.slice(1)
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["affiliate.sputnik24.tv/", /^\//]
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
