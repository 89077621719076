import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import statistics from './statistics'
import campaigns from "./campaigns"
import placements from './placements'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    statistics,
    campaigns,
    placements
  },
  strict: process.env.DEV
})
