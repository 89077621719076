export default {
    namespaced: true,
    state: {
        sortedFields: []
    },
    getters: {
        GET_FIELDS: state => {
            return state.sortedFields
        }
    },
    mutations: {
        SET_FIELDS: (state, payload) => {
            state.sortedFields = payload
        }
    },
    actions: {
        SET_FIELDS: (context, payload) => {
            context.commit('SET_FIELDS', payload)
        },

        RESET_FIELDS: (context) => {
            context.commit('SET_FIELDS', [])
        }
    }
}
